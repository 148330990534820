import Swiper, { Navigation, Thumbs } from 'swiper';

export default function sliderProduct() {
    (function ($) {
        /**
         * select trigger
         */
        const slider = '.js-product-slider';
        const sliderThumb = '.js-product-slider-thumb';
        const prev = '.js-product-slider-prev';
        const next = '.js-product-slider-next';
        const thumb_prev = '.js-product-slider-thumbs-prev';
        const thumb_next = '.js-product-slider-thumbs-next';

        let height = $('.js-product-slider').width(),
            count = $('.js-product-slider').data('count'),
            thumb_size = 123,
            slidesPerView = Math.floor(height / thumb_size) || 3,
            spaceBetween = 10;

        if (count < 5) {
            slidesPerView = count;
            spaceBetween = 8;
        }
        let productThumbSlider = null;
        if ($(sliderThumb).length > 0) {
            productThumbSlider = new Swiper(sliderThumb, {
                loop: false,

                slideActiveClass: 'is-active',
                slidesPerView: slidesPerView,
                spaceBetween: spaceBetween,
                watchSlidesProgress: true,
                modules: [Navigation],
                navigation: {
                    nextEl: thumb_next,
                    prevEl: thumb_prev,
                    lockClass: 'is-hidden',
                    disabledClass: 'is-disabled',
                },
            });
        }

        let thumbs = sliderThumb ? sliderThumb : '';

        const productSlider = new Swiper(slider, {
            loop: false,
            slideActiveClass: 'is-active',
            slidesPerView: 1,
            modules: [Navigation, Thumbs],
            thumbs: {
                swiper: productThumbSlider,
                slideThumbActiveClass: 'is-active-thumb',
            },
            navigation: {
                nextEl: next,
                prevEl: prev,
                lockClass: 'is-hidden',
                disabledClass: 'is-disabled',
            },
        });
    })(window.jQuery);
}
